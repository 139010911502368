import {
  useContext,
  useReducer,
  createContext,
  useCallback,
  Dispatch,
  ReactNode,
} from "react";
import { useRequest } from "../../services/request";
import createThumb from "utilities/createThumb";
import blobToBinary from "utilities/blobToBinary";
import { BaseStatus, Status } from "types/types";
import { JsonData, Product } from "views/products/provider";

export type Item = {
  id: number;
  name: string;
  description: string;
  image: string;
  thumbnail: string;
  nft: number;
  ipfs_hash: string;
  product: Product;
  json_data: JsonData;
  bound: boolean;
  nft_minting_status: string
};

export enum ItemsActionType {
  SetList,
  ChangeStatus,
  setParentId,
  setSelected,
  SetCount,
  ChangePage,
  ChangePerPage,
  Add,
  Edit,
  Delete,
}

type Action =
  | { type: ItemsActionType.SetList; payload: any[] }
  | { type: ItemsActionType.ChangeStatus; payload: Status }
  | { type: ItemsActionType.setSelected; payload: any }
  | { type: ItemsActionType.setParentId; payload: number }
  | { type: ItemsActionType.Add; payload: any }
  | { type: ItemsActionType.Edit; payload: any }
  | { type: ItemsActionType.Delete; payload: number }
  | { type: ItemsActionType.SetCount; payload: number }
  | { type: ItemsActionType.ChangePage; payload: number }
  | { type: ItemsActionType.ChangePerPage; payload: number };

type ItemsState = {
  list: any[];
  status: Status;
  selected: any | null;
  parentId: number | null;
  error: any;
  count: number;
  page: number;
  perPage: number;
};

type ItemContextType = {
  state: ItemsState;
  dispatch: Dispatch<Action>;
  fetchList: (
    id: number,
    page?: number,
    pageSize?: number,
    keyword?: string,
    bound?: string,
    nft?: string
  ) => void;
  fetchOne: (id: number) => void;
  createItem: (data: any, prodId: number) => void;
  editItem: (id: number, data: any) => void;
  deleteItem: (id: number) => void;
};

const ItemContext = createContext<ItemContextType | null>(null);

export function useItems() {
  const tagContext = useContext(ItemContext);

  if (!tagContext)
    throw new Error(
      "useItems has to be used within <ItemsProvider>. One possible solution is to add <ItemsProvider> to providers.js in /services"
    );

  return tagContext;
}

const initState: ItemsState = {
  list: [],
  parentId: null,
  selected: null,
  count: 0,
  page: 0,
  perPage: 10,
  status: BaseStatus.Idle,
  error: null,
};

const reducer = (state: ItemsState, action: Action): ItemsState => {
  switch (action.type) {
    case ItemsActionType.SetList:
      return { ...state, list: [...action.payload] };
    case ItemsActionType.setParentId:
      return { ...state, parentId: action.payload };
    case ItemsActionType.setSelected:
      return { ...state, selected: { ...action.payload } };
    case ItemsActionType.Add:
      const newList = [{ ...action.payload }, ...state.list];
      return { ...state, list: newList };
    case ItemsActionType.Edit:
      const modified = state.list.map((p) =>
        p.id === action.payload.id ? { ...p, ...action.payload } : p
      );
      return { ...state, list: modified };
    case ItemsActionType.Delete:
      const filtered = state.list.filter((p) => p.id !== action.payload);
      return { ...state, list: filtered };
    case ItemsActionType.ChangeStatus:
      return { ...state, status: action.payload };
    case ItemsActionType.SetCount:
      return { ...state, count: action.payload };
    case ItemsActionType.ChangePage:
      return { ...state, page: action.payload };
    case ItemsActionType.ChangePerPage:
      return { ...state, perPage: action.payload };

    // default:
    //   throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function ItemsProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();

  const fetchList = useCallback(
    (
      id: number,
      page = 0,
      pageSize = 10,
      keyword?: string,
      bound?: string,
      nft?: string
    ) => {
      return new Promise(async (resolve, reject) => {
        try {
          if (!id) throw new Error("id is required");
          dispatch({
            type: ItemsActionType.ChangeStatus,
            payload: BaseStatus.Fetching,
          });

          dispatch({ type: ItemsActionType.setParentId, payload: id });

          let query = `page=${page + 1}&page_size=${pageSize}`;
          if (keyword) query += `&keyword=${keyword}`;
          if (bound && bound !== "all") query += `&bound=${bound}`;
          if (nft && nft !== "all") query += `&has_nft=${nft}`;

          const resData = await req({
            url: `bulks/${id}/items/?${query}`,
            withAuth: true
          });
          dispatch({
            type: ItemsActionType.ChangeStatus,
            payload: BaseStatus.Idle,
          });
          dispatch({ type: ItemsActionType.SetList, payload: resData.results });
          dispatch({ type: ItemsActionType.SetCount, payload: resData.count });
          resolve(null);
        } catch (error: any) {
          console.error(error);
          reject(error);
        }
      });
    },
    [req]
  );

  const fetchOne = useCallback(
    (id: number) => {
      return new Promise(async (resolve) => {
        dispatch({
          type: ItemsActionType.ChangeStatus,
          payload: BaseStatus.Fetching,
        });
        const resData = await req({url: `items/${id}/`, withAuth: true});
        dispatch({ type: ItemsActionType.setSelected, payload: resData });
        dispatch({
          type: ItemsActionType.ChangeStatus,
          payload: BaseStatus.Idle,
        });
        resolve(resData);
      });
    },
    [req]
  );

  const _createImage = useCallback(
    (file: File[], silent = true): Promise<string> => {
      return new Promise(async (resolve, reject) => {
        if (!silent)
          dispatch({
            type: ItemsActionType.ChangeStatus,
            payload: BaseStatus.Uploading,
          });
        try {
          const binFile = await blobToBinary(file[0]);

          const resData = await req({
            url: `upload/${file[0].name}`,
            body: binFile,
            options: {
              method: "POST",
              headers: {
                accept: "*/*",
                "Accept-Language": "en-US,en;q=0.8",
                "Content-Type": file[0].type,
              },
            },
            withAuth: true,
            isFile: true
          });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          if (!silent)
            dispatch({
              type: ItemsActionType.ChangeStatus,
              payload: BaseStatus.Idle,
            });
        }
      });
    },
    [req]
  );

  const createItem = useCallback(
    (data: any, prodId: number): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        dispatch({
          type: ItemsActionType.ChangeStatus,
          payload: BaseStatus.Creating,
        });
        const newData = { ...data };
        try {
          if (data.image.length) {
            const imageData = await _createImage(data.image);

            const thumb = await createThumb(data.image[0], 300, 300);
            const thumbnailData = await _createImage([thumb]);

            newData.image = imageData;
            newData.thumbnail = thumbnailData;
          } else {
            delete newData.image;
          }
          // return;
          const resData = await req({
            url: `products/${prodId}/items/`,
            body: newData,
            options: { method: "POST" },
            withAuth: true
          });
          dispatch({ type: ItemsActionType.Add, payload: resData });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({
            type: ItemsActionType.ChangeStatus,
            payload: BaseStatus.Idle,
          });
        }
      });
    },
    [req, _createImage]
  );

  const editItem = useCallback(
    async (id: number, data: any): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        dispatch({
          type: ItemsActionType.ChangeStatus,
          payload: BaseStatus.Editing,
        });
        try {
          const resData = await req({
            url: `items/${id}/`,
            body: data,
            options: { method: "PATCH" },
            withAuth: true
          });
          dispatch({ type: ItemsActionType.Edit, payload: resData });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({
            type: ItemsActionType.ChangeStatus,
            payload: BaseStatus.Idle,
          });
        }
      });
    },
    [req]
  );

  const deleteItem = useCallback(
    async (id: number) => {
      dispatch({
        type: ItemsActionType.ChangeStatus,
        payload: `${BaseStatus.Deleting}_${id}`,
      });
      await req({url: `items/${id}/`, options: { method: "DELETE" }, withAuth: true});
      dispatch({
        type: ItemsActionType.ChangeStatus,
        payload: BaseStatus.Idle,
      });
      dispatch({ type: ItemsActionType.Delete, payload: id });
    },
    [req]
  );

  // const changePage = useCallback(
  //   (p) => dispatch({ type: "set_page", payload: p }),
  //   []
  // );

  // const changePerPage = useCallback((e) => {
  //   dispatch({
  //     type: "set_per_page",
  //     payload: parseInt(e.target.value, 10),
  //   });
  //   dispatch({ type: "set_page", payload: 0 });
  // }, []);

  return (
    <ItemContext.Provider
      value={{
        state,
        dispatch,
        fetchList,
        fetchOne,
        deleteItem,
        createItem,
        editItem,
        // changePage,
        // changePerPage,
      }}
    >
      {children}
    </ItemContext.Provider>
  );
}
